<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <!-- 入荷予定データ取込 -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_arrivaldataDifferenceimport" />
      <v-form ref="mainForm" lazy-validation>
        <v-container fluid>
          <!-- 検索項目 -->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item" style="width: 450px">
              <v-autocomplete
                dense
                v-model="searchForm.suppliersSelected"
                @change="clearSupp"
                :items="searchForm.supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷予定日 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="searchForm.referenceDate"
                :label="$t('label.lbl_arrivalScheduleDate')"
                @change="changeDate('referenceDateCal', $event)"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.yyyymmdd]"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="searchForm.dateMenu2"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="searchForm.referenceDateCal"
                  @input="searchForm.dateMenu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevDate('referenceDateCal')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDate('referenceDateCal')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ファイル -->
            <!-- 取込ファイル -->
            <!-- <div class="file-input">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                :rules="[rules.fileRequired]"
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                ref="vFileInput"
              >
              </v-file-input>
            </div> -->
            <div class="file-input">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                :rules="this.compareBtnFileFlg ? [] : [rules.fileRequired]"
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                ref="vFileInput"
              >
              </v-file-input>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ボタン -->
            <div class="file-input">
              <v-btn class="post-btn" @click="search()">
                {{ $t("btn.btn_compareImport") }}
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <div style="width: 100px"></div>
            <div class="search-row-exeBtn first-search-item">
              <!-- 画面クリアボタン -->
              <div class="btn-area" style="float: left">
                <v-btn class="other-btn" @click="viewConfirmDialog('btn_clear')">{{
                  $t("btn.btn_clear")
                }}</v-btn>
              </div>

              <div class="btn-area">
                <!-- 差分確認ボタン -->
                <v-btn class="post-btn" @click="compareConfirm()">{{
                  $t("btn.btn_diffComparison")
                }}</v-btn>
              </div>

              <!--数量合計領域-->
              <div
                style="
                  float: left;
                  display: flex;
                  align-items: center;
                  width: 66%;
                  flex-direction: row-reverse;
                "
              >
                <div
                  style="
                    margin-left: 85%;
                    font-weight: bold;
                    font-size: large;
                    border-bottom: double;
                  "
                >
                  <!-- 数量合計-->
                  {{ $t("label.lbl_totalQty") + " " + this.sumQty.toLocaleString() }}
                </div>
              </div>
            </div>
          </v-row>
          <v-row>
            <span class="item-spacer">&nbsp;</span>
            <!--検索結果件数表示-->
            <div class="text-label" style="float: left">
              <span class="toatlNum">{{
                $t("label.lbl_total") + "：" + totalCount + $t("label.lbl_number")
              }}</span>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :server-items-length="searchForm.itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="750px"
          :options.sync="sortOptions"
        >
          <!-- ヘッダーロット -->
          <template v-slot:[`header.lotNo`]="{ header }">
            <span>
              {{ header.text }}<br />
              {{
                $t("label.lbl_dueDate") +
                "／" +
                $t("label.lbl_lotSubkey1") +
                "／" +
                $t("label.lbl_lotSubkey2")
              }}
            </span>
          </template>

          <template v-slot:[`item.No`]="{ item }">
            <!-- No -->
            <div v-bind:class="{ isD: item.isInListNoFlg }"></div>
            <div v-bind:class="{ 'listNo-style': true, redText: item.isReplaceFlag === '0' }">
              {{ item.No }}
            </div>
          </template>
          <template v-slot:[`item.inListNo`]="{ item }">
            <!-- 入荷No -->
            <div v-bind:class="{ isD: item.isInListNoFlg }"></div>
            <div v-bind:class="{ 'listNo-style': true, redText: item.isReplaceFlag === '0' }">
              {{ item.inListNo }}
            </div>
          </template>
          <template v-slot:[`item.shipInsDate`]="{ item }">
            <!-- 入荷予定日 -->
            <div
              v-bind:class="{
                isD: item.isShipInsDateFlg,
                redText: item.isReplaceFlag === '0',
              }"
            >
              {{ item.shipInsDate }}
            </div>
          </template>
          <template v-slot:[`item.product`]="{ item }">
            <!-- 品番 -->
            <div v-bind:class="{ isD: item.isItemCdFlg, redText: item.isReplaceFlag === '0' }">
              {{ item.itemCd }}
            </div>
            <!-- 付帯品番 -->
            <div v-bind:class="{ isD: item.isItemCdFlg, redText: item.isReplaceFlag === '0' }">
              {{ item.itemSubCd }}
            </div>
          </template>
          <template v-slot:[`item.productName`]="{ item }">
            <!-- 品番 -->
            <div v-bind:class="{ isD: item.isItemNameFlg, redText: item.isReplaceFlag === '0' }">
              {{ item.itemName }}
            </div>
          </template>
          <template v-slot:[`item.lotNo`]="{ item }">
            <!-- ロット -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 280px" v-bind="attrs" v-on="on">
                  {{ item.lotNo }}<br />
                  {{ item.dueDate }}
                </div>
              </template>
              <span class="text-overflow">
                {{ item.lotNo }}<br />
                {{ item.dueDate }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.registeredCount`]="{ item }">
            <!-- 登録済数量 -->
            <div v-bind:class="{ isD: item.isFromNameFlg, redText: item.isReplaceFlag === '0' }">
              {{ item.registeredNum }}
            </div>
          </template>
          <template v-slot:[`item.uptakeCount`]="{ item }">
            <!-- 取込数量-->
            <div v-bind:class="{ isD: item.isFromNameFlg, redText: item.isReplaceFlag === '0' }">
              {{ item.uptakeNum }}
            </div>
          </template>
          <template v-slot:[`item.situation`]="{ item }">
            <!-- 状況-->
            <div v-bind:class="{ isD: item.isFromNameFlg, redText: item.isReplaceFlag === '0' }">
              {{ item.situation }}
            </div>
          </template>
          <template v-slot:[`item.instruction`]="{ item }">
            <!-- 指示-->
            <div v-bind:class="{ isD: item.isFromNameFlg, redText: item.isReplaceFlag === '0' }">
              <div style="white-space: pre-line; text-align: left" v-text="item.instruction" />
            </div>
          </template>
          <template v-slot:[`item.accInfos`]="{ item }">
            <!-- エラー内容 -->
            <!-- <div v-bind:class="{ isD: item.isAddInfoFlg }">{{ item.accInfos }}</div> -->
            <div v-if="item.accInfos != null">
              <!-- 品番／品名  改行&ツールチップ -->
              <div v-if="item.accInfos.length > 9">
                <!-- 品名が9文字以上だった場合ツールチップで表示 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-truncate"
                      style="max-width: 400px"
                      v-bind="attrs"
                      v-on="on"
                      v-bind:class="{ isD: item.isAddInfoFlg }"
                    >
                      <span v-html="item.accInfos"></span>
                    </div>
                  </template>
                  <span v-html="item.accInfos"></span>
                </v-tooltip>
              </div>
              <div v-else>
                <!-- 品名が9文字以下だった場合ツールチップ無しで表示 -->
                <span v-html="item.accInfos"></span>
              </div>
            </div>
          </template>
        </v-data-table>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000" persistent no-click-animation>
          <ReceivingDataImportError
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :rcvSchListFlg="infoDialog.rcvSchListFlg"
        :rcvSchAddFlg="infoDialog.rcvSchAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
      />

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="compareDialog.isOpen"
        :message="compareDialog.message"
        :screenFlag="compareDialog.screenFlag"
        :okAction="diffComparison"
      />
      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="importDialog.isOpen"
        :message="importDialog.message"
        :screenFlag="importDialog.screenFlag"
        :okAction="diffComparison"
      />
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
// import HeaderBar from "@/components/HeaderBar.vue";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import ReceivingDataImportError from "../receiving/ReceivingDataImportError.vue";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import commonMixinExcel from "@/mixins/CommonMixinExcel";

export default {
  name: appConfig.SCREEN_ID.P_RCV_002,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
    ReceivingDataImportError,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules, commonMixinExcel],
  data: () => ({
    file: {},
    fileData: {},
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    importfile: {},
    // ファイル名
    fileName: {},
    // S3ファイル名
    s3FileName: {},
    searchForm: {
      // 取引先
      suppliersSelected: "",
      supplierList: [],
      suppliersSelectedName: "",
      // データページスクロール処理
      itemsPerPage: 1,
      // 入荷予定日
      arrivalScheduleDate: "",
      arrivalScheduleDateCal: "",
      dateMenu: false,
      // 入荷予定日
      referenceDispFlg: false,
      referenceDate: "",
      referenceDateCal: "",
      dateMenu2: false,
    },
    // メッセージダイアログ
    compareDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // メッセージダイアログ
    importDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // 責任
    isRes: "01",
    isResBadValue: "01",
    // 有償/無償
    isPaid: "01",
    // 総件数
    totalCount: 0,
    // 品質区分
    arrivalStatusSelected: "01",
    isDisabledFreeReason: true,
    isDisabledBlame: true,
    // 無償理由
    freeReasonSelected: "",
    // 処理区分
    processDivName: "01",
    // 入荷予定配列
    inListCsvData: [],
    // 差分確認ボタン押下時ファイルチェック抜き用
    compareBtnFileFlg: false,
    // 取込確定フラグ
    confirmFlg: false,
    // メニュー
    openMenu: null,
    // 表示の切り替え
    activate: false,
    // 数量合計
    sumQty: "",
    freeCostDivList: [],
    blameDivList: [],
    arrivalStatusList: [],
    freeReasonList: [],
    processDivList: [],
    // 詳細ポップアップ
    dialogDetails: false,
    errorDetailDialogData: [],
    achievementFlgCheckList: [],
    comparisonResultCdCheckList: [],
    // シート数
    sheetNum: 0,
    // 取込データ
    output: [],
    sheetInfo: [],
    // ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "3%",
        sortable: true,
        align: "right",
      },
      // 入荷No
      {
        text: i18n.tc("label.lbl_slipNo"),
        value: "inListNo",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 入荷予定日
      {
        text: i18n.tc("label.lbl_arrivalScheduleDate"),
        value: "shipInsDate",
        width: "6%",
        align: "left",
        sortable: true,
      },
      // 品番/付帯品番
      {
        text: i18n.tc("label.lbl_productCode") + "/" + i18n.tc("label.lbl_accessoryProduct"),
        value: "product",
        width: "9%",
        align: "left",
        sortable: true,
      },
      //品名
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "itemName",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lotNo",
        width: "13%",
        align: "left",
        sortable: true,
      },
      // 登録済数量
      {
        text: i18n.tc("label.lbl_registeredCount"),
        value: "registeredNum",
        width: "6%",
        align: "left",
        sortable: true,
      },
      // 取込数量
      {
        text: i18n.tc("label.lbl_uptakeCount"),
        value: "uptakeNum",
        width: "6%",
        align: "left",
        sortable: true,
      },
      // 状況
      {
        text: i18n.tc("label.lbl_Situation"),
        value: "situation",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 指示
      {
        text: i18n.tc("label.lbl_Instruction"),
        value: "instruction",
        width: "12%",
        align: "left",
        sortable: false,
      },
    ],
    inputList: [],
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      suppliersSelectedName: "",
      list: [],
    },
  }),
  methods: {
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    /**
     * 初期表示
     */
    init() {
      // 取引先取得
      this.getCustomInfo();
      // マスタ取得
      this.getMstCode();
      this.searchForm.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
    },
    /**
     * 入荷予定日初期化
     */
    initReferenceDate() {
      this.searchForm.referenceDispFlg = false;
      this.searchForm.referenceDate = "";
      this.searchForm.referenceDateCal = "";
    },
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      switch (button) {
        case "btn_clear":
          isValidationRequired = true;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_clear")]);
          action = this.clear;
          break;
        case "btn_upload":
          isValidationRequired = true;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_upload")]);
          action = this.csvConfirm;
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        // 処理なし
      }
      this.ConfirmDialog.message = message;
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = action;
    },
    /**
     * 入荷予定登録画面：日付を－１日
     */
    prevDate(modelName) {
      if (this.searchForm[modelName] == null || this.searchForm[modelName] == "") {
        return;
      }
      let date = new Date(this.searchForm[modelName]);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.searchForm[modelName] != toDate) {
        this.searchForm[modelName] = toDate;
      } else {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 入荷予定登録画面：日付を＋１日
     */
    nextDate(modelName) {
      if (this.searchForm[modelName] == null || this.searchForm[modelName] == "") {
        return;
      }
      let date = new Date(this.searchForm[modelName]);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.searchForm[modelName] != toDate) {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";
      config.params.reqComComponentId = appConfig.SCREEN_ID.P_RCV_007;

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getCustomInfo() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.compSid, // 取引先Sid
                  officeCd: row.clientCd, // 取引先Cd
                });
              });
              this.searchForm.supplierList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 処理区分
    setStsArrival() {
      for (var i = 0; i < this.processDivList.length; i++) {
        if (this.processDivList[i].value == this.processDivName) {
          return this.processDivList[i].name;
        }
      }
    },
    /**
     * 画面クリア押下
     */
    clear() {
      this.confirmFlg = false;
      this.inputList = [];
      this.freeReasonSelected = "";
      this.processDivName = "01";
      this.searchForm.arrivalScheduleDate = "";
      this.searchForm.arrivalScheduleDateCal = "";
      this.searchForm.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.importfile = null;
      this.totalCount = 0;
      this.arrivalStatusSelected = "01";
      this.activate = false;
      this.isPaid = "01";
      this.sumQty = "";
      this.searchForm.suppliersSelected = "";
      // ボタン識別フラグ（falseだったら、画面クリア）
      this.confirmFlg = false;
      // バリデーションチェックをリセット
      this.$refs.mainForm.resetValidation();
    },
    /**
     * 取引先変更
     */
    clearSupp() {
      this.confirmFlg = false;
      this.inputList = [];
      this.freeReasonSelected = "";
      this.processDivName = "01";
      this.totalCount = 0;
      this.arrivalStatusSelected = "01";
      this.activate = false;
      this.isPaid = "01";
      this.sumQty = "";
      // バリデーションチェックをリセット
      this.$refs.mainForm.resetValidation();
    },

    /**
     * 取込前のバリデーションチェック
     */
    search() {
      // 初期化
      this.inputList = [];
      this.totalCount = 0;
      this.sumQty = "";

      if (this.$refs.mainForm.validate()) {
        // 取込確定確認POPUP処理
        this.csvImport();
      }
    },

    /**
     * 取込ボタン押下処理
     */
    csvImport() {
      // 取込確定処理
      // 選択したファイルを読み込む
      const selectFile = this.importfile;
      // 拡張子チェック
      let fileType = selectFile.type;
      // 取り込んだファイルはCSVの場合
      if (fileType == "text/csv") {
        this.importCSV(selectFile)
          .then((result) => {
            this.inListCsvData = result;
          })
          .then(() => {
            this.postCsv();
          });
      } else if (
        fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType == "application/vnd.ms-excel"
      ) {
        // 取り込んだファイルはエクセルの場合
        this.importExcel(selectFile)
          .then((result) => {
            this.inListCsvData = result;
          })
          .then(() => {
            this.postCsv();
          });
      } else {
        // 取り込んだファイルはCSVまたはExcel以外場合エラーメッセージを表示する
        this.infoDialog.message = i18n.tc("check.chk_limitSelectFileType");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }
    },

    /**
     * マスタコード取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.IN_PROCESS_DIV);

      Promise.all([qualityList, reasonList, freeCostList, blameList, processList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.arrivalStatusList = result[0];
          this.freeReasonList = result[1];
          this.freeCostDivList = result[2];
          // 責任区分の最初のなしを削除
          result[3].splice(0, 1);
          this.blameDivList = result[3];
          this.processDivList = result[4];
          this.processDivName = this.processDivList[0].value;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * POSTAPI(本登録POST)
     */
    csvConfirm() {
      this.confirmFlg = true;
      if (this.confirmFlg) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const body = this.$httpClient.createRequestBodyConfig();

        //  画面ID TODO:修正必要
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;
        // 取引先SID
        body.reqIdv.clientSid = this.searchForm.suppliersSelected;
        // 取引先コード
        body.reqIdv.clientCd = this.getSupplierCd();
        // 品質区分
        body.reqIdv.qualityDiv = this.arrivalStatusSelected;
        // 責任区分
        body.reqIdv.blameDiv = this.isRes;
        // 有償無償
        body.reqIdv.freeCostDiv = this.isPaid;
        // 無償理由
        body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
        // 処理区分（画面上にないから追加）
        body.reqIdv.processDiv = this.processDivName;
        // S3ファイル名
        // body.reqIdv.s3FileName = this.s3FileName;
        body.reqIdv.fileName = this.s3FileName;
        // 入出荷フラグ
        body.reqIdv.inOutDiv = "0003";
        // 入荷予定日
        if (this.searchForm.arrivalScheduleDate) {
          body.reqIdv.inScheduleDate = this.searchForm.arrivalScheduleDate.substr(0, 10);
        }
        // 入荷予定日
        if (this.searchForm.referenceDate) {
          body.reqIdv.referenceDate = this.searchForm.referenceDate.substr(0, 10);
        }
        // 変更理由
        body.reqIdv.changeReasonDiv = "";
        // 路線
        body.reqIdv.carrierCompKbn = "";
        // 路線会社
        body.reqIdv.carrierCompSid = "";
        // 入荷予定配列
        body.reqIdv.listCsvData = this.inListCsvData;

        // console.debug("csvConfirm() Body", body);

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_INSCHDULESFILEMAIN, body, appConfig.APP_CONFIG)
            .then((response) => {
              // console.debug("csvConfirm() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                // 画面遷移ボタン TODO：修正する
                this.infoDialog.homePageFlag = true;
                this.infoDialog.rcvSchListFlg = true;
                this.infoDialog.rcvSchAddFlg = true;
                this.infoDialog.firstPageFlag = false;
                this.clearFlg = true;

                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                // エラー時
                // const errorList = [];
                // エラー情報
                // jsonData.resIdv.errors.forEach((row) => {
                //   errorList.push({
                //     // 行数
                //     row: row.row,
                //     // 項目数
                //     columnName: row.columnName,
                //   });
                // });
                reject(resolve);
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              this.clear();
            });
        });
      } else {
        // 画面クリア処理
        this.inputList = [];
        this.importfile = null;
        this.totalCount = 0;
        this.arrivalStatusSelected = "01";
        this.activate = false;
        this.freeReasonSelected = "";
        this.processDivName = "01";
        this.isPaid = "01";
        // バリデーションチェックをリセット
        this.$refs.vFileInput.resetValidation();
      }
    },
    compareConfirm() {
      // 差分確認ボタン押下時ファイルチェック抜き用
      // this.compareBtnFileFlg = true;
      // this.$refs.vFileInput.resetValidation();
      // 取込前のバリデーションチェック
      this.compareBtnFileFlg = true;
      if (
        this.searchForm.suppliersSelected == null ||
        this.searchForm.suppliersSelected == "" ||
        this.searchForm.referenceDate == null ||
        this.searchForm.referenceDate == ""
      ) {
        this.$refs.mainForm.validate();
      } else {
        // if (this.$refs.searchForm.resetValidation()) {
        // ボタン識別フラグ（trueだったら、取込確定）
        this.compareDialog.isOpen = true;
        this.compareDialog.screenFlag = true;
        this.compareDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
          i18n.tc("btn.btn_diffComparison"),
        ]);
      }
    },
    diffComparison() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_002;

      // 取引先SID
      body.reqIdv.clientSid = this.searchForm.suppliersSelected;
      // ファイル名
      if (this.importfile != null) {
        body.reqIdv.fileName = this.importfile.name;
      }
      // 入荷予定日
      // 指示日
      if (this.searchForm.arrivalScheduleDate) {
        body.reqIdv.inScheduleDate = this.searchForm.arrivalScheduleDate.substr(0, 10);
      }
      // 入荷予定日
      if (this.searchForm.referenceDate) {
        body.reqIdv.referenceDate = this.searchForm.referenceDate.substr(0, 10);
      }
      // 入荷予定配列
      body.reqIdv.listCsvData = this.inListCsvData;
      // タイムゾーン
      body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
      // 入出荷フラグ(0003)
      body.reqIdv.inOutDiv = "0003";
      // 取引先コード
      body.reqIdv.clientCd = this.getSupplierCd();
      // 品質区分
      body.reqIdv.qualityDiv = this.arrivalStatusSelected;
      // 責任区分
      body.reqIdv.blameDiv = this.isRes;
      // 有償無償
      body.reqIdv.freeCostDiv = this.isPaid;
      // 無償理由
      body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
      // 処理区分
      body.reqIdv.processDiv = this.processDivName;
      // 変更理由
      body.reqIdv.changeReasonDiv = "";
      // 路線
      body.reqIdv.carrierCompKbn = "";
      // 路線会社
      body.reqIdv.carrierCompSid = "";
      // console.debug("postCsv() Body", body);
      console.log("postCsv() Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_DIFFINSCHDULESFILEMAIN, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("postCsv() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              // 画面表示項目
              jsonData.resIdv.inScheduleFileCompare.forEach((row) => {
                let errMsg = "";
                let rcvDate = "";
                if (row.errMsg) {
                  errMsg = row.errMsg;
                  errMsg = errMsg.replace("\r\n", "<br />");
                  this.activate = false;
                }
                if (row.scheduleDateYmd.length == 8) {
                  rcvDate =
                    row.scheduleDateYmd.substr(0, 4) +
                    "/" +
                    row.scheduleDateYmd.substr(4, 2) +
                    "/" +
                    row.scheduleDateYmd.substr(6, 2);
                } else if (row.scheduleDate.length == 6) {
                  rcvDate =
                    row.scheduleDateYmd.substr(0, 2) +
                    "/" +
                    row.scheduleDateYmd.substr(2, 2) +
                    "/" +
                    row.scheduleDateYmd.substr(4, 2);
                } else {
                  rcvDate =
                    row.scheduleDateYmd.substr(0, 4) +
                    "/" +
                    row.scheduleDateYmd.substr(5, 2) +
                    "/" +
                    row.scheduleDateYmd.substr(8, 2);
                }
                let lotObj = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  // No
                  No: "",
                  // 入荷No
                  inListNo: row.inListNo,
                  // 入荷予定日
                  // shipInsDate: row.scheduleDate,
                  shipInsDate: rcvDate,
                  // rcvInDate: rcvDate,
                  // 品番
                  itemCd: row.itemCd,
                  // 付帯品番
                  itemSubCd: row.itemSubCd ?? "",
                  // 品名
                  itemName: row.itemName,
                  // ロット、S/N
                  lotNo: lotObj.lot,
                  // 期限日、付属キー１、付属キー２
                  dueDate: lotObj.dueDate,
                  // 登録済数量
                  registeredNum: row.dbScheduleQuantity,
                  // 取込数量
                  uptakeNum: row.csvScheduleQuantity,
                  // 入荷元
                  fromName: row.fromName,
                  //状況
                  situation: row.procCd,
                  //指示
                  instruction: row.instruction,
                  // 入荷元SID
                  fromSid: row.fromSid,
                  // 出荷先SID
                  arrivalto: row.fromName,
                  //入荷伝票SID
                  arrSid: row.inListSid,
                  //入荷元名
                  fromBaseCd: row.fromCd,
                  //商品標準SID
                  itemStandardSid: row.itemStandardSid,
                  //顧客
                  clientSid: row.clientSid,
                  isPaid: row.freeCostDiv,
                  blameDiv: row.blameDiv,
                  processDivName: row.processDiv,
                  reasonCdName: row.reasonCd,
                  processingdivision: row.processDiv,
                  // 付帯情報
                  // addInfo: row.addInfo,
                  accInfos: errMsg,
                  csvProcessDiv: row.csvProcessDiv,
                  // errInList:0か1か入って来てエラーか判断する
                });
              });
              // 画面表示用リストに格納
              this.inputList = list;
              this.isErr();
              // 総件数取得
              this.totalCount = this.inputList.length;
              // 数量合計取得
              var cnt = 0;
              this.sumQty = 0;

              for (var i = 0; i < this.inputList.length; i++) {
                cnt = cnt + this.inputList[i].uptakeNum;
                this.inputList[i].No = String(i + 1);
                this.setInstruction(this.inputList[i]);
                this.setSituation(this.inputList[i]);
              }
              this.sumQty = cnt;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    isErr() {
      let countErr = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].isInListNoFlg) {
          countErr += 1;
        }
      }
      if (countErr == 0) {
        this.activate = true;
      }
    },

    /**
     * POSTAPI(仮登録POST)
     */
    postCsv() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;

      // 取引先SID
      body.reqIdv.clientSid = this.searchForm.suppliersSelected;
      // ファイル名
      body.reqIdv.fileName = this.importfile.name;
      // 入荷予定日
      // 指示日
      if (this.searchForm.arrivalScheduleDate) {
        body.reqIdv.inScheduleDate = this.searchForm.arrivalScheduleDate.substr(0, 10);
      }
      // 入荷予定日
      if (this.searchForm.referenceDate) {
        body.reqIdv.referenceDate = this.searchForm.referenceDate.substr(0, 10);
      }
      // 入荷予定配列
      body.reqIdv.listCsvData = this.inListCsvData;
      // タイムゾーン
      body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
      // 入出荷フラグ(0003)
      body.reqIdv.inOutDiv = "0003";
      // 取引先コード
      body.reqIdv.clientCd = this.getSupplierCd();
      // 品質区分
      body.reqIdv.qualityDiv = this.arrivalStatusSelected;
      // 責任区分
      body.reqIdv.blameDiv = this.isRes;
      // 有償無償
      body.reqIdv.freeCostDiv = this.isPaid;
      // 無償理由
      body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
      // 処理区分
      body.reqIdv.processDiv = this.processDivName;
      // 変更理由
      body.reqIdv.changeReasonDiv = "";
      // 路線
      body.reqIdv.carrierCompKbn = "";
      // 路線会社
      body.reqIdv.carrierCompSid = "";

      // console.debug("postCsv() Body", body);
      console.log("postCsv() Body", body);

      this.confirmFlg = true;
      if (this.confirmFlg) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const body = this.$httpClient.createRequestBodyConfig();

        //  画面ID TODO:修正必要
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;
        // 取引先SID
        body.reqIdv.clientSid = this.searchForm.suppliersSelected;
        // 取引先コード
        body.reqIdv.clientCd = this.getSupplierCd();
        // 品質区分
        body.reqIdv.qualityDiv = this.arrivalStatusSelected;
        // 責任区分
        body.reqIdv.blameDiv = this.isRes;
        // 有償無償
        body.reqIdv.freeCostDiv = this.isPaid;
        // 無償理由
        body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
        // 処理区分（画面上にないから追加）
        body.reqIdv.processDiv = this.processDivName;
        // S3ファイル名
        // body.reqIdv.s3FileName = this.s3FileName;
        //body.reqIdv.fileName = this.importfile.name;
        // 入出荷フラグ
        body.reqIdv.inOutDiv = "0003";
        // 入荷予定日
        if (this.searchForm.arrivalScheduleDate) {
          body.reqIdv.inScheduleDate = this.searchForm.arrivalScheduleDate.substr(0, 10);
        }
        // 入荷予定日
        if (this.searchForm.referenceDate) {
          body.reqIdv.referenceDate = this.searchForm.referenceDate.substr(0, 10);
        }
        // 変更理由
        body.reqIdv.changeReasonDiv = "";
        // 路線
        body.reqIdv.carrierCompKbn = "";
        // 路線会社
        body.reqIdv.carrierCompSid = "";
        // 入荷予定配列
        body.reqIdv.listCsvData = this.inListCsvData;

        // console.debug("csvConfirm() Body", body);

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_DIFFINSCHDULESFILE, body, appConfig.APP_CONFIG)
            .then((response) => {
              // console.debug("csvConfirm() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                if (jsonData.resIdv.inOutDiff.csvErrorCheckResultList.length != 0) {
                  const errorList = [];
                  this.errorDetailDialogData = [];
                  jsonData.resIdv.inOutDiff.csvErrorCheckResultList.forEach((row) => {
                    errorList.push({
                      listNo: row.listNo,
                      row: row.row,
                      column: row.column,
                      message: row.message,
                      value: row.value,
                    });
                    this.errorDetailDialogData = errorList;
                  });
                  // エラー一覧タブが開く
                  // 詳細画面へ渡す値を設定します。
                  this.detailDialogData.list = this.errorDetailDialogData;
                  // 選択された列名
                  this.detailDialogData.suppliersSelectedName = this.setSrhSupplier();
                  this.dialogDetails = true;
                } else {
                  this.importDialog.message = messsageUtil.getMessage("A-999-999_001_I", [
                    i18n.tc("btn.btn_compareImport"),
                  ]);
                  this.importDialog.message += messsageUtil.getMessage("P-RCV-007_001_C");
                  this.importDialog.title = appConfig.DIALOG.title;
                  this.importDialog.isOpen = true;
                  this.importDialog.screenFlag = true;
                  this.importDialog.firstPageFlag = true;

                  resolve(response);
                }
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject(resolve);
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     * 日付入力(From)
     */
    changeDate(modelName, val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.searchForm[modelName] = null;
      }
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      const supplier = this.searchForm.supplierList.find(
        (item) => item.value == this.searchForm.suppliersSelected
      );
      return supplier ? supplier.text : "";
    },
    /**
     * 指示取得処理
     */
    setInstruction(item) {
      if (item.instruction == "01") {
        if (item.situation == "100") {
          item.instruction = i18n.tc("label.lbl_arrived");
        } else {
          item.instruction = i18n.tc("label.lbl_Ent");
        }
        item.instruction += i18n.tc("label.lbl_receivingDataImpotDel");
      } else if (item.instruction == "02") {
        item.instruction = i18n.tc("label.lbl_receivingDataImpotDec");
      } else if (item.instruction == "03") {
        item.instruction = i18n.tc("label.lbl_receivingDataImpotAdd");
      } else {
        item.instruction = i18n.tc("label.lbl_receivingDataImpotUpdate");
      }
    },
    /**
     * 状況取得処理
     */
    setSituation(item) {
      if (item.situation == "100") {
        item.situation = i18n.tc("label.lbl_arrived");
      } else {
        item.situation = i18n.tc("label.lbl_Ent");
      }
    },

    /**
     * 取引先コード取得
     */
    getSupplierCd() {
      const supplier = this.searchForm.supplierList.find(
        (item) => item.value == this.searchForm.suppliersSelected
      );
      return supplier ? supplier.officeCd : "";
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * アイコンを押下しても、エクスプローラーが開く処理
     */
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    "searchForm.arrivalScheduleDateCal": function (val) {
      this.searchForm.arrivalScheduleDate = this.formatDate(val);
    },
    "searchForm.referenceDateCal": function (val) {
      this.searchForm.referenceDate = this.formatDate(val);
    },
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
        // this.processDivName = "";
      }
    },
    arrivalStatusSelected: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.isRes = this.isResBadValue;
      } else {
        this.isDisabledBlame = true;
        this.isResBadValue = this.isRes;
        this.isRes = "01";
      }
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "No") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                // console.log("a.No: " + isNaN(a.No));
                return a.No - b.No;
              } else {
                return b.No - a.No;
              }
            });
          } else if (sortBy == "inListNo") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.inListNo < b.inListNo) {
                  return -1;
                } else if (a.inListNo > b.inListNo) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.inListNo > b.inListNo) {
                  return -1;
                } else if (a.inListNo < b.inListNo) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "shipInsDate") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.shipInsDate < b.shipInsDate) {
                  return -1;
                } else if (a.shipInsDate > b.shipInsDate) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.shipInsDate > b.shipInsDate) {
                  return -1;
                } else if (a.shipInsDate < b.shipInsDate) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "registeredNum") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.registeredNum - b.registeredNum;
              } else {
                return b.registeredNum - a.registeredNum;
              }
            });
          } else if (sortBy == "uptakeNum") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.uptakeNum - b.uptakeNum;
              } else {
                return b.uptakeNum - a.uptakeNum;
              }
            });
          } else if (sortBy == "fromName") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.fromName < b.fromName) {
                  return -1;
                } else if (a.fromName > b.fromName) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.fromName > b.fromName) {
                  return -1;
                } else if (a.fromName < b.fromName) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "product") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.itemCd < b.itemCd) {
                  return -1;
                } else if (a.itemCd > b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.itemCd > b.itemCd) {
                  return -1;
                } else if (a.itemCd < b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//送り先登録時、色変更
.isD {
  color: $color-error !important;
}

//備考欄、テキストボックス
.search-textbox-remark {
  width: 40rem;
}

/* 赤い文字色を定義するクラス */
.redText {
  color: red !important;
}

#listData.v-data-table ::v-deep th {
  padding: 0 12px;
}
</style>
