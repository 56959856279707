<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <!-- 入荷予定データ取込 -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_arrivaldataimport" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 検索項目 -->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item" style="width: 450px">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷予定日付-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrivalScheduleDate"
                :label="$t('label.lbl_arrivalScheduleDate')"
                @change="changeDate"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertDateMessage"
                :rules="[rules.yyyymmdd]"
              ></v-text-field>
            </div>

            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="arrivalScheduleDateCal"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ファイル -->
            <div class="file-input">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                :rules="[rules.fileRequired]"
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                ref="vFileInput"
                :error-messages="alertMessage"
              >
              </v-file-input>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ボタン -->
            <v-btn class="post-btn" @click="search()">
              {{ $t("btn.btn_import") }}
            </v-btn>
          </v-row>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品質区分-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="arrivalStatusSelected"
                :items="arrivalStatusList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
                :error-messages="alertArrivalStatusMessage"
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 責任区分-->
            <v-btn-toggle v-model="isRes" color="blue darken-2" class="toggle black--text">
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in blameDivList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledBlame"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 有償/無償-->
            <div style="float: left">
              <v-btn-toggle
                mandatory
                v-model="isPaid"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in freeCostDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}
                </v-btn>
              </v-btn-toggle>
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 無償理由選択プルダウン-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                class="list-single"
                v-model="freeReasonSelected"
                :items="freeReasonList"
                v-bind:disabled="isDisabledFreeReason"
                :label="$t('label.lbl_freeReason')"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 処理区分-->
            <div class="search-autocomplete first-search-item" style="float: left">
              <v-autocomplete
                dense
                v-model="processDivName"
                :items="processDivList"
                :label="$t('label.lbl_processingdivision')"
                :hint="setStsArrival()"
                class="txt-single"
                persistent-hint
                :error-messages="alertProcessDivNameMessage"
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <div style="width: 100px"></div>
            <div class="search-row-exeBtn first-search-item">
              <!--検索結果件数表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>
              <!--ボタン領域-->
              <div class="btn-area">
                <!-- アップロードボタン -->
                <v-btn
                  class="post-btn"
                  @click="viewConfirmDialog('btn_upload')"
                  v-bind:disabled="!activate"
                  >{{ $t("btn.btn_upload") }}</v-btn
                >

                <!-- 画面クリアボタン -->
                <v-btn class="other-btn" @click="viewConfirmDialog('btn_clear')">{{
                  $t("btn.btn_clear")
                }}</v-btn>
              </div>
              <!--表示件数切替部分-->
              <!-- <div style="float: right"> -->
              <!--表示件数-->
              <!-- <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div> -->

              <!-- 空白 -->
              <!-- <span class="item-spacer" style="float: left">&nbsp;</span> -->

              <!--表示件数切替ボタン-->
              <!-- <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div> -->
              <!-- </div> -->

              <!--数量合計領域-->
              <div
                style="
                  float: left;
                  display: flex;
                  align-items: center;
                  width: 66%;
                  flex-direction: row-reverse;
                "
              >
                <div
                  style="
                    margin-left: 85%;
                    font-weight: bold;
                    font-size: large;
                    border-bottom: double;
                  "
                >
                  <!-- 数量合計-->
                  {{ $t("label.lbl_totalQty") + " " + this.sumQty.toLocaleString() }}
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="750px"
          :options.sync="sortOptions"
        >
          <template v-slot:[`item.inListNo`]="{ item }">
            <!-- 入荷No -->
            <div v-bind:class="{ isD: item.isInListNoFlg }"></div>
            <div class="listNo-style">{{ item.inListNo }}</div>
          </template>
          <template v-slot:[`item.shipInsDate`]="{ item }">
            <!-- 入荷予定日 -->
            <div v-bind:class="{ isD: item.isShipInsDateFlg }">{{ item.shipInsDate }}</div>
          </template>
          <template v-slot:[`item.product`]="{ item }">
            <!-- 品番/品名 -->
            <div v-bind:class="{ isD: item.isItemCdFlg }">{{ item.itemCd }}</div>
            <div v-bind:class="{ isD: item.isItemNameFlg }">{{ item.itemSubCd }}</div>
          </template>
          <template v-slot:[`item.inScheduleQuantity`]="{ item }">
            <!-- 数量 -->
            <div v-bind:class="{ isD: item.isInQtyeFlg }">
              {{ item.inScheduleQuantity.toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.fromName`]="{ item }">
            <!-- 入荷元 -->
            <div v-bind:class="{ isD: item.isFromNameFlg }">{{ item.fromName }}</div>
          </template>
          <template v-slot:[`item.accInfos`]="{ item }">
            <!-- エラー内容 -->
            <!-- <div v-bind:class="{ isD: item.isAddInfoFlg }">{{ item.accInfos }}</div> -->
            <div v-if="item.accInfos != null">
              <!-- 品番／品名  改行&ツールチップ -->
              <div v-if="item.accInfos.length > 9">
                <!-- 品名が9文字以上だった場合ツールチップで表示 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-truncate"
                      style="max-width: 400px"
                      v-bind="attrs"
                      v-on="on"
                      v-bind:class="{ isD: item.isAddInfoFlg }"
                    >
                      <span v-html="item.accInfos"></span>
                    </div>
                  </template>
                  <span v-html="item.accInfos"></span>
                </v-tooltip>
              </div>
              <div v-else>
                <!-- 品名が9文字以下だった場合ツールチップ無しで表示 -->
                <span v-html="item.accInfos"></span>
              </div>
            </div>
          </template>
        </v-data-table>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000" persistent no-click-animation>
          <ReceivingDataImportError
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
        <!-- ページネーション -->
        <!-- <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div> -->
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :rcvSchListFlg="infoDialog.rcvSchListFlg"
        :rcvSchAddFlg="infoDialog.rcvSchAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
      />

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
// import HeaderBar from "@/components/HeaderBar.vue";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import ReceivingDataImportError from "../receiving/ReceivingDataImportError.vue";
import commonMixin from "@/mixins/CommonMixin";
import commonMixinExcel from "@/mixins/CommonMixinExcel";

export default {
  name: appConfig.SCREEN_ID.P_RCV_002,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
    ReceivingDataImportError,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonMixinExcel],
  data: () => ({
    file: {},
    fileData: {},
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    importfile: {},
    // 入荷予定日
    arrivalScheduleDate: "",
    arrivalScheduleDateCal: "",
    // カレンダー
    dateMenu: false,
    // ファイル名
    fileName: {},
    // S3ファイル名
    s3FileName: {},
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    suppliersSelectedName: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 責任
    isRes: "01",
    isResBadValue: "01",
    // 有償/無償
    isPaid: "01",
    // 総件数
    totalCount: 0,
    // 品質区分
    arrivalStatusSelected: "01",
    isDisabledFreeReason: true,
    isDisabledBlame: true,

    message: "Try Preview!",
    url: "",

    // 無償理由
    freeReasonSelected: "",
    // 処理区分
    processDivName: "01",
    // 入荷予定配列
    inListCsvData: [],
    // 取込確定フラグ
    confirmFlg: false,
    // メニュー
    openMenu: null,
    csvData: [],
    // 表示の切り替え
    activate: false,
    // 数量合計
    sumQty: "",
    // カラム数
    headColumn: 0,
    freeCostDivList: [],
    blameDivList: [],
    arrivalStatusList: [],
    freeReasonList: [],
    processDivList: [],
    // エラーメッセージ
    alertMessage: "",
    alertDateMessage: "",
    // 詳細ポップアップ
    dialogDetails: false,
    errorDetailDialogData: [],
    // メッセージ必須チェック
    alertArrivalStatusMessage: "",
    alertProcessDivNameMessage: "",
    // シート数
    sheetNum: 0,
    // 取込データ
    output: [],
    sheetInfo: [],
    // ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "4%",
        sortable: true,
        align: "right",
      },
      // 入荷No
      {
        text: i18n.tc("label.lbl_slipNo"),
        value: "inListNo",
        width: "17%",
        align: "left",
        sortable: true,
      },
      // 入荷日
      {
        text: i18n.tc("label.lbl_arrivalScheduleDate"),
        value: "shipInsDate",
        width: "8%",
        align: "center",
        sortable: true,
      },
      // 品番/付帯品番
      {
        text: i18n.tc("label.lbl_productCode") + "/" + i18n.tc("label.lbl_accessoryProduct"),
        value: "product",
        width: "20%",
        align: "left",
        sortable: true,
      },
      // 品名
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "itemName",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "inScheduleQuantity",
        width: "5%",
        align: "right",
        sortable: true,
      },
      // 入荷元
      {
        text: i18n.tc("label.lbl_arrival"),
        value: "fromName",
        width: "20%",
        align: "left",
        sortable: true,
      },
    ],
    inputList: [],
    rules: {
      yyyymmdd: function (value) {
        if (!value) {
          return true;
        }
        if (
          !(
            dateTimeHelper.validDate(commonUtil.zen2han(value), "/") && dateTimeHelper.isDate(value)
          )
        ) {
          return i18n.tc("check.chk_inputDate");
        }
        return true;
      },
      // yyyymmdd: (value) =>
      //   (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
      //     dateTimeHelper.isDate(value)) ||
      //   i18n.tc("check.chk_inputDate"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      fileRequired: (value) => (!!value && !!value.name) || i18n.tc("check.chk_select"),
    },
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      suppliersSelectedName: "",
      list: [],
    },
  }),
  methods: {
    uploadFile() {
      const file = this.$refs.preview.files[0];
      this.url = URL.createObjectURL(file);
      // let filePath =
      console.log("this.url: " + this.url);
    },
    // // ファイル入力が変更された時呼び出されます。
    // onChangeFileInput(file) {
    //   this.fileData = new Object();
    //   // ファイルタイプを設定します。
    //   this.fileData.type = file.type;
    //   // ファイル名を設定します。
    //   this.fileData.fileName = file.name;

    //   // ファイルデータを非同期で読み込みます。
    //   this.readFileAsync(file)
    //     .then((result) => {
    //       // ファイルデータが読み込めた場合
    //       // ファイルデータを設定します。
    //       this.fileData.data = result;
    //     })
    //     .catch((e) => {
    //       // エラーの場合
    //       console.log(`e=${e}`);
    //     });
    // },
    // // ファイルデータを非同期で読み込みます。
    // readFileAsync(file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       resolve(reader.result);
    //     };
    //     reader.onerror = reject;
    //     reader.readAsArrayBuffer(file);
    //   });
    // },
    // ダイアログオープン
    openPopup() {
      this.dialogDetails = true;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    // u_10_check() {
    //   if (this.itemsPerPage < 11) {
    //     this.u_10 = true;
    //   }
    // },
    // u_10_on() {
    //   this.u_10 = true;
    //   this.itemsPerPage = 10;
    //   this.page = 1;
    //   // 取り込み結果のAPIを設定するTODO
    //   this.search(false);
    // },
    // u_10_clear(val) {
    //   this.u_10 = false;
    //   this.itemsPerPage = val;
    //   this.page = 1;
    //   // 取り込み結果のAPIを設定するTODO
    //   this.search(false);
    // },

    /**
     * 初期表示
     */
    init() {
      this.suppliersSelected = "";
      this.txt_arrivalScheduleNo = "";
      this.txt_slipNo = "";
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.inputList = [];
      this.freeReasonSelected = "";
      this.processDivName = "";
      this.arrivalScheduleDate = "";
      this.arrivalScheduleDateCal = "";

      // 取引先取得
      this.getCustomInfo();
      // マスタ取得
      this.getMstCode();
    },
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      switch (button) {
        case "btn_clear":
          isValidationRequired = true;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_clear")]);
          action = this.clear;
          break;
        case "btn_upload":
          isValidationRequired = true;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_upload")]);
          action = this.csvConfirm;
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        // 処理なし
      }
      this.ConfirmDialog.message = message;
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = action;
    },
    /**
     * 入荷予定登録画面：入荷予定日付を－１日
     */
    prevDate() {
      if (this.arrivalScheduleDateCal == null || this.arrivalScheduleDateCal == "") {
        return;
      }
      let date = new Date(this.arrivalScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrivalScheduleDateCal != toDate) {
        this.arrivalScheduleDateCal = toDate;
      } else {
        this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 入荷予定登録画面：入荷予定日付を＋１日
     */
    nextDate() {
      if (this.arrivalScheduleDateCal == null || this.arrivalScheduleDateCal == "") {
        return;
      }
      let date = new Date(this.arrivalScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrivalScheduleDateCal != toDate) {
        this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getCustomInfo() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.compSid, // 取引先Sid
                  officeCd: row.clientCd, // 取引先Cd
                });
              });
              this.supplierList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 処理区分
    setStsArrival() {
      for (var i = 0; i < this.processDivList.length; i++) {
        if (this.processDivList[i].value == this.processDivName) {
          return this.processDivList[i].name;
        }
      }
    },
    /**
     * 画面クリア押下
     */
    clear() {
      this.confirmFlg = false;
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.inputList = [];
      this.freeReasonSelected = "";
      this.processDivName = "01";
      this.arrivalScheduleDate = "";
      this.arrivalScheduleDateCal = "";
      this.importfile = null;
      this.totalCount = 0;
      this.arrivalStatusSelected = "01";
      this.activate = false;
      this.isPaid = "01";
      this.sumQty = "";
      this.suppliersSelected = "";
      // ボタン識別フラグ（falseだったら、画面クリア）
      this.confirmFlg = false;
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    /**
     * 取込前のバリデーションチェック
     */
    search() {
      // 初期化
      this.inputList = [];
      this.totalCount = 0;
      this.sumQty = "";
      if (this.arrivalScheduleDate) {
        if (
          !(
            dateTimeHelper.validDate(commonUtil.zen2han(this.arrivalScheduleDate), "/") &&
            dateTimeHelper.isDate(this.arrivalScheduleDate)
          )
        ) {
          this.alertDateMessage = i18n.tc("check.chk_inputDate");
          return;
        }
      }
      if (this.arrivalStatusSelected == null && this.arrivalStatusSelected == "") {
        // エラー
        this.alertArrivalStatusMessage = i18n.tc("check.chk_input");
        return;
      } else {
        // 処理なし
      }
      if (this.processDivName == null && this.processDivName == "") {
        // エラー
        this.alertProcessDivNameMessage = i18n.tc("check.chk_input");
        return;
      } else {
        // 処理なし
      }
      if (this.$refs.form.validate()) {
        // 取込確定確認POPUP処理
        this.csvImport();
      }
    },

    /**
     * 取込ボタン押下処理
     */
    csvImport() {
      // 取込確定処理
      // 選択したファイルを読み込む
      const selectFile = this.importfile;
      // 拡張子チェック
      let fileType = selectFile.type;

      // 取り込んだファイルはCSVの場合
      if (fileType == "text/csv") {
        this.importCSV(selectFile)
          .then((result) => {
            this.inListCsvData = result;
          })
          .then(() => {
            this.postCsv();
          });
      } else if (
        fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType == "application/vnd.ms-excel"
      ) {
        // 取り込んだファイルはエクセルの場合
        this.importExcel(selectFile)
          .then((result) => {
            this.inListCsvData = result;
          })
          .then(() => {
            this.postCsv();
          });
      } else {
        // 取り込んだファイルはCSVまたはExcel以外場合エラーメッセージを表示する
        this.infoDialog.message = i18n.tc("check.chk_limitSelectFileType");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }
    },
    /**
     * マスタコード取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.IN_PROCESS_DIV);

      Promise.all([qualityList, reasonList, freeCostList, blameList, processList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.arrivalStatusList = result[0];
          this.freeReasonList = result[1];
          this.freeCostDivList = result[2];
          // 責任区分の最初のなしを削除
          result[3].splice(0, 1);
          this.blameDivList = result[3];
          this.processDivList = result[4];
          this.processDivName = this.processDivList[0].value;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * POSTAPI(本登録POST)
     */
    csvConfirm() {
      this.confirmFlg = true;
      if (this.confirmFlg) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const body = this.$httpClient.createRequestBodyConfig();

        //  画面ID TODO:修正必要
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;
        // 取引先SID
        body.reqIdv.clientSid = this.suppliersSelected;
        // 取引先コード
        body.reqIdv.clientCd = this.getSupplierCd();
        // 品質区分
        body.reqIdv.qualityDiv = this.arrivalStatusSelected;
        // 責任区分
        body.reqIdv.blameDiv = this.isRes;
        // 有償無償
        body.reqIdv.freeCostDiv = this.isPaid;
        // 無償理由
        body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
        // 処理区分（画面上にないから追加）
        body.reqIdv.processDiv = this.processDivName;
        // S3ファイル名
        // body.reqIdv.s3FileName = this.s3FileName;
        body.reqIdv.fileName = this.s3FileName;
        // 入出荷フラグ
        body.reqIdv.inOutDiv = "0003";
        // 入荷予定日
        if (this.arrivalScheduleDate) {
          body.reqIdv.inScheduleDate = this.arrivalScheduleDate.substr(0, 10);
        }
        // 変更理由
        body.reqIdv.changeReasonDiv = "";
        // 路線
        body.reqIdv.carrierCompKbn = "";
        // 路線会社
        body.reqIdv.carrierCompSid = "";
        // 入荷予定配列
        body.reqIdv.listCsvData = this.inListCsvData;

        // console.debug("csvConfirm() Body", body);

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_INSCHDULESFILEMAIN, body, appConfig.APP_CONFIG)
            .then((response) => {
              // console.debug("csvConfirm() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                // 画面遷移ボタン TODO：修正する
                this.infoDialog.homePageFlag = true;
                this.infoDialog.rcvSchListFlg = true;
                this.infoDialog.rcvSchAddFlg = true;
                this.infoDialog.firstPageFlag = false;
                this.clearFlg = true;

                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                // エラー時
                // const errorList = [];
                // エラー情報
                // jsonData.resIdv.errors.forEach((row) => {
                //   errorList.push({
                //     // 行数
                //     row: row.row,
                //     // 項目数
                //     columnName: row.columnName,
                //   });
                // });
                reject(resolve);
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              this.clear();
            });
        });
      } else {
        // 画面クリア処理
        this.inputList = [];
        this.importfile = null;
        this.totalCount = 0;
        this.arrivalStatusSelected = "01";
        this.activate = false;
        this.freeReasonSelected = "";
        this.processDivName = "01";
        this.itemsPerPage = 10;
        this.itemsPerPageBtn = "10";
        this.isPaid = "01";
        // バリデーションチェックをリセット
        this.$refs.vFileInput.resetValidation();
      }
    },
    isErr() {
      let countErr = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].isInListNoFlg) {
          countErr += 1;
        }
      }
      if (countErr == 0) {
        this.activate = true;
      }
    },

    /**
     * POSTAPI(仮登録POST)
     */

    postCsv() {
      // メッセージ初期化
      this.alertArrivalStatusMessage = "";
      this.alertProcessDivNameMessage = "";
      this.alertDateMessage = "";

      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;

      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // ファイル名
      body.reqIdv.fileName = this.importfile.name;
      // 入荷予定日
      // 指示日
      if (this.arrivalScheduleDate) {
        body.reqIdv.inScheduleDate = this.arrivalScheduleDate.substr(0, 10);
      }
      // 入荷予定配列
      body.reqIdv.listCsvData = this.inListCsvData;
      // タイムゾーン
      body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
      // 入出荷フラグ(0003)
      body.reqIdv.inOutDiv = "0003";
      // 取引先コード
      body.reqIdv.clientCd = this.getSupplierCd();
      // 品質区分
      body.reqIdv.qualityDiv = this.arrivalStatusSelected;
      // 責任区分
      body.reqIdv.blameDiv = this.isRes;
      // 有償無償
      body.reqIdv.freeCostDiv = this.isPaid;
      // 無償理由
      body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
      // 処理区分
      body.reqIdv.processDiv = this.processDivName;
      // 変更理由
      body.reqIdv.changeReasonDiv = "";
      // 路線
      body.reqIdv.carrierCompKbn = "";
      // 路線会社
      body.reqIdv.carrierCompSid = "";
      // ページャー処理
      // if (this.page >= 2 && this.inputList.length == 0) {
      //   body.reqCom.reqComPageIndex = "1";
      //   this.page = 1;
      // } else {
      //   body.reqCom.reqComPageIndex = this.page;
      // }
      // // ページング
      // body.reqCom.reqComPaginationFlg = "1";
      // body.reqCom.reqComPageLimit = this.itemsPerPage;
      // this.sortBy == null;

      // if (this.sortItem != "") {
      //   body.reqCom.reqComSortItem = this.sortItem;
      // }

      // console.debug("postCsv() Body", body);
      console.log("postCsv() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_INSCHDULESFILE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("postCsv() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (jsonData.resIdv.schedulesFileTmp.csvErrorCheckResultList.length != 0) {
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.schedulesFileTmp.csvErrorCheckResultList.forEach((row) => {
                  errorList.push({
                    listNo: row.listNo,
                    row: row.row,
                    column: row.column,
                    message: row.message,
                    value: row.value,
                  });
                  this.errorDetailDialogData = errorList;
                });
                // エラー一覧タブが開く
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                // 選択された列名
                this.detailDialogData.suppliersSelectedName = this.setSrhSupplier();
                this.dialogDetails = true;
              } else {
                // this.pageCount = jsonData.resCom.resComPagination.totalPage;
                const list = [];
                // ファイル名
                this.fileName = jsonData.resIdv.schedulesFileTmp.fileName;
                // S3登録ファイル名
                this.s3FileName = jsonData.resIdv.schedulesFileTmp.s3FileName;
                // 画面表示項目
                jsonData.resIdv.schedulesFileTmp.scheduleCsvResults.forEach((row) => {
                  let isErrFlg = false;
                  let errMsg = "";
                  let rcvDate = "";
                  if (row.errMsg) {
                    isErrFlg = true;
                    errMsg = row.errMsg;
                    errMsg = errMsg.replace("\r\n", "<br />");
                    this.activate = false;
                  }
                  if (row.scheduleDate.length == 8) {
                    rcvDate =
                      row.scheduleDate.substr(0, 4) +
                      "/" +
                      row.scheduleDate.substr(4, 2) +
                      "/" +
                      row.scheduleDate.substr(6, 2);
                  } else if (row.scheduleDate.length == 6) {
                    rcvDate =
                      row.scheduleDate.substr(0, 2) +
                      "/" +
                      row.scheduleDate.substr(2, 2) +
                      "/" +
                      row.scheduleDate.substr(4, 2);
                  } else {
                    rcvDate =
                      row.scheduleDate.substr(0, 4) +
                      "/" +
                      row.scheduleDate.substr(5, 2) +
                      "/" +
                      row.scheduleDate.substr(8, 2);
                  }
                  list.push({
                    isInListNoFlg: isErrFlg,
                    isShipInsDateFlg: isErrFlg,
                    isItemCdFlg: isErrFlg,
                    isItemNameFlg: isErrFlg,
                    isInQtyeFlg: isErrFlg,
                    isFromNameFlg: isErrFlg,
                    isAddInfoFlg: isErrFlg,
                    // No
                    No: row.no,
                    // 入荷No
                    inListNo: row.listNo,
                    // 入荷予定日
                    // shipInsDate: row.scheduleDate,
                    shipInsDate: rcvDate,
                    // rcvInDate: rcvDate,
                    // 品番
                    itemCd: row.itemCd,
                    // 品名
                    itemName: row.itemName,
                    // 付帯品番
                    itemSubCd: row.itemSubCd ?? "",
                    // ロット
                    lotNo: row.lotNo,
                    // 数量
                    inScheduleQuantity: row.scheduleQuantity,
                    // 入荷元
                    fromName: row.fromName,
                    // 入荷元SID
                    fromSid: row.fromSid,
                    // 出荷先SID
                    toSid: row.toSid,
                    // 付帯情報
                    // addInfo: row.addInfo,
                    accInfos: errMsg,
                    csvProcessDiv: row.csvProcessDiv,
                    // errInList:0か1か入って来てエラーか判断する
                  });
                });
                // 画面表示用リストに格納
                this.inputList = list;
                this.isErr();
                // 総件数取得
                this.totalCount = this.inputList.length;
                // 数量合計取得
                var cnt = 0;
                this.sumQty = 0;
                for (var i = 0; i < this.inputList.length; i++) {
                  cnt = cnt + this.inputList[i].inScheduleQuantity;
                }
                this.sumQty = cnt;
                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 日付入力(From)
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrivalScheduleDateCal = null;
      }
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].text;
        }
      }
    },
    /**
     * 取引先コー取得
     */
    getSupplierCd() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].officeCd;
        }
      }
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * アイコンを押下しても、エクスプローラーが開く処理
     */
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    arrivalScheduleDateCal(val) {
      this.arrivalScheduleDate = this.formatDate(val);
      this.alertDateMessage = "";
    },
    page: function (newValue) {
      this.page = newValue;
      this.search(false);
    },
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
        // this.processDivName = "";
      }
    },
    arrivalStatusSelected: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.isRes = this.isResBadValue;
      } else {
        this.isDisabledBlame = true;
        this.isResBadValue = this.isRes;
        this.isRes = "01";
      }
    },

    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "No") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                // console.log("a.No: " + isNaN(a.No));
                return a.No - b.No;
              } else {
                return b.No - a.No;
              }
            });
          } else if (sortBy == "inListNo") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.inListNo < b.inListNo) {
                  return -1;
                } else if (a.inListNo > b.inListNo) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.inListNo > b.inListNo) {
                  return -1;
                } else if (a.inListNo < b.inListNo) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "shipInsDate") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.shipInsDate < b.shipInsDate) {
                  return -1;
                } else if (a.shipInsDate > b.shipInsDate) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.shipInsDate > b.shipInsDate) {
                  return -1;
                } else if (a.shipInsDate < b.shipInsDate) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "inScheduleQuantity") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.inScheduleQuantity - b.inScheduleQuantity;
              } else {
                return b.inScheduleQuantity - a.inScheduleQuantity;
              }
            });
          } else if (sortBy == "fromName") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.fromName < b.fromName) {
                  return -1;
                } else if (a.fromName > b.fromName) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.fromName > b.fromName) {
                  return -1;
                } else if (a.fromName < b.fromName) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "product") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.itemCd < b.itemCd) {
                  return -1;
                } else if (a.itemCd > b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.itemCd > b.itemCd) {
                  return -1;
                } else if (a.itemCd < b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//送り先登録時、色変更
.isD {
  color: $color-error !important;
}

//備考欄、テキストボックス
.search-textbox-remark {
  width: 40rem;
}
</style>
